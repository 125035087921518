export function formatDate(dateInput, status) {
  const formatSingleDate = (dateString, status) => {
    const date = new Date(dateString);

    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    if (status == 0) {
      const weekdays = [
        "domingo", "segunda-feira", "terça-feira", "quarta-feira", 
        "quinta-feira", "sexta-feira", "sábado"
      ];

      const weekday = weekdays[date.getDay()];
      return `${weekday}, ${day}/${month}/${year} às ${hours}:${minutes}h`;
    }

    if (status == 1) {
      return `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };

  if (Array.isArray(dateInput)) {
    return dateInput.map(date => formatSingleDate(date, status));
  } else {
    return formatSingleDate(dateInput, status);
  }
}
