import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

loadFonts();

const app = createApp(App);
app.use(router).use(vuetify);

library.add(fas, far);
app.component('font-awesome-icon', FontAwesomeIcon);
app.config.globalProperties.$filters = {
  faIcon(value) {
    return ['fa', value];
  }
};
app.mount("#app");
