<template>
  <div class="wrapper">
    <div class="container d-flex justify-space-between">
      <div class="data">
        <p class="title">
          {{ item.version_name }}
          <span class="version">(Versão {{ item.version }})</span>
        </p>
        <p class="subtitle">
          {{ formatDate(item.updated_at, (status = 1)) }} por {{ item.user }}
        </p>
      </div>

      <div class="status">
        <div>
          <v-chip
            class="error-chip"
            @click="getErrors(item.id)"
            v-if="item.status == 'Visualizar erros'"
            color="white"
          >
            <template v-slot:prepend>
              <font-awesome-icon :icon="['fas', 'circle-xmark']" />
            </template>
            Visualizar erros</v-chip
          >
          <ErrorsPoppup
            :dialog="showDialog"
            @update:dialog="showDialog = $event"
            @open-dialog="OpenDialoghandler"
            :errors="errors_return"
          ></ErrorsPoppup>
        </div>

        <v-chip
          class="completed-chip"
          v-if="item.status == 'Importação finalizada'"
          color="white"
        >
          <template v-slot:prepend>
            <font-awesome-icon :icon="['fas', 'circle-check']" />
          </template>
          Importação finalizada</v-chip
        >
        <v-chip
          class="waiting-chip"
          v-if="item.status == 'Aguardando'"
          color="white"
        >
          <template v-slot:prepend>
            <font-awesome-icon
              class="fa-fade"
              :icon="['far', 'clock']" /></template
          >Aguardando</v-chip
        >
        <v-chip
          class="importing-chip"
          v-if="item.status == 'Importando'"
          color="white"
        >
          <template v-slot:prepend>
            <font-awesome-icon
              class="fa-spin"
              :icon="['fas', 'circle-notch']" /></template
          >Importando</v-chip
        >
        <v-btn
          variant="plain"
          :ripple="false"
          icon="mdi-download"
          @click="downloadFile(item.id)"
        ></v-btn>
      </div>
    </div>
    <div class="hr"></div>
  </div>
</template>

<style>
.title {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.version {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.subtitle {
  color: #808080;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.status {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
  margin-right: 56px;
}

.data p {
  margin-left: 19px;
  margin-top: 12px;
  line-height: 12px;
}

.container {
  height: 68px;
  border-radius: 4px;
  border: 1px solid var(--Light-Gray-System, #d4d4d4);
}

.hr {
  width: 1px;
  height: 20px;
  background-color: #d4d4d4 !important;
  margin-top: 0;
  margin-left: 55px;
}

.wrapper:last-of-type .hr {
  display: none;
}

.completed-chip {
  height: 24px;
  padding: 8px 14px;
  border-radius: 16px;
  background-color: #23bf6a;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.waiting-chip {
  height: 24px;
  padding: 8px 14px;
  border-radius: 16px;
  background-color: #a2a2a2;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  --fa-animation-duration: 2s;
  --fa-fade-opacity: 0.45;
}

.error-chip {
  height: 21px;
  padding: 8px 14px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: #f7685b;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.checking-chip {
  height: 21px;
  padding: 8px 14px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: #cccaca;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.importing-chip {
  height: 21px;
  padding: 8px 14px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: #0da6a6;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
</style>

<script>
import { formatDate } from "@/utils/dataformatter";
import { Get } from "@/http/http.js";
import ErrorsPoppup from "./ErrorsPoppup.vue";
export default {
  components: {
    ErrorsPoppup,
  },
  data() {
    return {
      path: null,
      get_return: null,
      errors_return: null,
      showDialog: false,
    };
  },
  props: {
    item: Array,
  },
  mounted() {
    formatDate();
  },
  methods: {
    formatDate,
    async downloadFile(id) {
      this.path = "readfile/" + id;
      this.get_return = await Get(this.path);
      location.href = this.get_return.file_link;
    },

    async getErrors(id) {
      this.path = "importerrors/" + id;
      this.errors_return = await Get(this.path);
      (this.showDialog = true)
    },
    OpenDialoghandler(value) {
      this.$emit("DialogStatus", value);
    },
  },
};
</script>
