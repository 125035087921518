export async function APIAuth(path, jwt) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    };
    const response = await fetch("/api/" + path, { headers });
    const auth = await response.json();
    return auth.sucesso;
  } catch (error) {
    console.error(error);
  }
}
export async function Get(path) {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch("/api/" + path, { headers });
    const data = await response.json();
    if (data.success) {
      return data.data;
    }

    return data.success;
  } catch (error) {
    console.error(error);
  }
}

export async function Patch(path) {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch("/api/" + path, {
      headers: headers,
      method: "PATCH",
    });
    const data = await response.json();

    if (data.success) {
      return data.data;
    }

    return data.success;
  } catch (error) {
    console.error(error);
  }
}

export async function Post(value, path) {
  try {
    let formData = new FormData();
    formData.append("value", value);

    const response = await fetch("/api/" + path, {
      method: "POST",
      body: formData,
    });
    const result = response.json();

    return result;
  } catch (error) {
    console.error(error);
  }
}

function uuidGenerator() {
  const idFromStorage = sessionStorage.getItem("uuid");
  if (idFromStorage) {
    return idFromStorage;
  } else {
    const id = crypto.randomUUID();
    sessionStorage.setItem("uuid", id);
    return id;
  }
}

export async function PostWithFile(
  path,
  data,
  from_to_columns,
  selected_versions,
  configs,
  status = 0
) {
  try {
    let formData = new FormData();
    formData.append("uuid", uuidGenerator());

    if (from_to_columns != null) {
      formData.append("from_to_columns", JSON.stringify(from_to_columns));
    }

    if (selected_versions != null) {
      formData.append("selected_versions", JSON.stringify(selected_versions));
    }

    if (configs != null) {
      formData.append("configs", JSON.stringify(configs));
    }

    if (data != null) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        formData.append(element.name, element.value);
      }
    }

    const response = await fetch("/api/" + path, {
      method: "POST",
      body: formData,
    });
    const auth = await response.json();
    if (status == 0) {
      return auth.success;
    } else {
      return auth;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ValidateFile(path, file, controller) {
  try {
    let formData = new FormData();
    formData.append("uuid", uuidGenerator());
    formData.append("arquivo", file[0]);

    const response = await fetch("/api/" + path, {
      method: "POST",
      body: formData,
      signal: controller.signal,
    });
    const auth = await response.json();
    return auth;
  } catch (error) {
    if (error.name == "AbortError") {
      console.error("Requisição abortada.");
    }
    return false;
  }
}
