<template>
  <div @click="backToOrigin" class="d-flex mt-1 ml-3">
    <v-btn
      style="
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
      "
      variant="plain"
      prepend-icon="mdi-arrow-left"
      >{{ buttonText }}</v-btn
    >
  </div>
</template>

<style>
.btn-content:hover {
  cursor: pointer;
}
</style>

<script>
export default {
  props: {
    buttonText: String,
  },
  methods: {
    backToOrigin() {
      if (this.$route.name == "datadefinitions") {
        this.$router.push("/");
      }
      if (this.$route.name == "configuration") {
        this.$router.push("/model");
      }
    },
  },
};
</script>
