<template>
  <div class="cards-wrapper">
    <v-container fluid>
      <v-row class="d-flex flex-wrap">
        <v-col
          v-for="item in items"
          :key="item"
          cols="12"
          sm="9"
          :md="md"
          :lg="lg"
          :xl="xlg"
          class="d-flex"
        >
          <v-card class="mx-auto flex-grow-1" :style="CardStyle">
            <template v-slot:title>
              <v-sheet class="text-left d-flex" style="text-wrap: wrap">
                <v-sheet
                  :style="TitleStyle"
                  class="data-type-name ma-0 pa-0 me-auto"
                >
                  {{ item.name }}
                </v-sheet>
                <v-sheet v-if="item.icon != none" class="ma-0 pa-0">
                  <v-img
                    width="22px"
                    :src="require('../assets/' + item.icon + '.svg')"
                  ></v-img>
                </v-sheet>
                <v-sheet v-if="item.field_required == true" class="ma-0 pa-0">
                  <v-img
                    width="18px"
                    :src="require('../assets/obrigatorio.svg')"
                  ></v-img>
                </v-sheet>
                <v-sheet v-if="item.field_required == false" class="ma-0 pa-0">
                  <v-img
                    width="18px"
                    :src="require('../assets/opcional.svg')"
                  ></v-img>
                </v-sheet>
              </v-sheet>
              <v-sheet :style="TypeStyle" class="data-type">{{
                item.data_type
              }}</v-sheet>
            </template>

            <v-card-text
              :style="DescStyle"
              class="data-type-desc mt-5 text-left"
            >
              {{ item.desc }}
            </v-card-text>

            <div class="selection-part ml-4" v-if="autocomplete_activated">
              <div class="d-flex mb-2">
                <v-text style="font-family: Roboto; color: #1a1a1a"
                  >Vincule um campo do sistema</v-text
                >
                <v-tooltip
                  max-width="300px"
                  max-height="150px"
                  text="Informe o campo da sua plataforma que contém essa informação."
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      color="white"
                      style="
                        margin-left: 11px;
                        margin-bottom: 2px;
                        background-color: #757575;
                        padding: 10px;
                        font-size: 15px;
                        border-radius: 100%;
                      "
                      icon="mdi-exclamation-thick"
                    ></v-icon>
                  </template>
                </v-tooltip>
              </div>
              <v-autocomplete
                no-data-text="Campo não encontrado"
                :disabled="item.default_field == 1 ? true : false"
                label="Campo do sistema"
                item-title="nome"
                item-value="coluna"
                :items="custom_fields"
                variant="solo"
                v-model="item.column"
                @update:model-value="
                  (val) => disableSelectedItem(val, item.data_fields_id)
                "
                ><template v-slot:item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :disabled="item?.raw?.disabled ?? false"
                  ></v-list-item> </template
              ></v-autocomplete>
            </div>

            <div
              style="display: flex"
              v-if="
                item.updated_at != none ||
                item.active != none ||
                this.$attrs.onAction
              "
            >
              <div class="card-divider"></div>
              <v-card-actions
                class="pa-0 ma-0 d-flex flex-wrap"
                style="
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <v-text
                  v-if="item.updated_at"
                  class="ml-5 mr-5"
                  style="
                    color: #808080;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 129.2%;
                    letter-spacing: 0.35px;
                  "
                >
                  Última atualização: {{ formatDate(item.updated_at) }}
                </v-text>
                <v-text
                  v-if="item.updated_at == none && item.active == none"
                  style="
                    color: #808080;
                    font-style: normal;
                    margin-left: 10px;
                    padding: 3px 12px;
                    border-radius: 20px;
                    font-size: 14px;
                    color: white;
                    background-color: #a2a2a2;
                  "
                >
                  Sem dados disponíveis
                </v-text>
                <v-text
                  v-if="item.updated_at == none && item.active == 0"
                  style="
                    color: #808080;
                    font-style: normal;
                    margin-left: 10px;
                    padding: 3px 12px;
                    border-radius: 20px;
                    font-size: 14px;
                    color: white;
                    background-color: #a2a2a2;
                  "
                >
                  Desativado
                </v-text>
                <v-text
                  v-if="item.updated_at == none && item.active == 1"
                  style="
                    color: #808080;
                    font-style: normal;
                    margin-left: 10px;
                    padding: 3px 12px;
                    border-radius: 20px;
                    font-size: 14px;
                    color: white;
                    background-color: #23bf6a;
                  "
                >
                  Ativado
                </v-text>

                <v-btn
                  v-if="this.$attrs.onAction"
                  @click="passEvent(item)"
                  style="
                    letter-spacing: normal;
                    text-transform: none;
                    font-size: 14px;
                    margin-right: 16px;
                  "
                  variant="text"
                >
                  Acessar
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar
        style="margin-left: 105px"
        v-model="snackbar"
        :timeout="snackbar_options.timeout"
        multi-line
        position="sticky"
        location="left bottom"
      >
        {{ snackbar_options.text }}

        <template v-slot:actions>
          <v-btn
            :color="snackbar_options.color"
            variant="text"
            @click="snackbar = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<style>
.selection-part {
  position: absolute;
  bottom: 0;
  width: calc(100% - 32px);
}
.cards-wrapper {
  margin-right: 22px;
}
.card-divider {
  position: absolute;
  height: 1px;
  bottom: 52px;
  width: 100%;
  background-color: #d4d4d4;
}
</style>

<script>
import { formatDate } from "@/utils/dataformatter";
import { Post } from "@/http/http";
export default {
  data() {
    return {
      custom_fields: this.$route.meta.custom_fields,
      selectedItem: null,
      post_result: null,
      snackbar: false,
      snackbar_options: {
        text: null,
        color: null,
        timeout: null,
      },
    };
  },
  props: {
    autocomplete_activated: {
      default: false,
    },
    actionActive: null,
    items: {
      type: Array,
    },

    cardWidth: {
      type: String,
      default: null,
    },

    cardHeight: {
      type: String,
      default: "250px",
    },
    md: {
      type: String,
      default: "6",
    },
    lg: {
      type: String,
      default: "4",
    },
    xlg: {
      type: String,
      default: "3",
    },
    titlecolor: String,
    titlefontfamily: String,
    titlefontsize: String,
    titlefontstyle: String,
    titlefontweight: String,
    titlelineheight: String,

    typecolor: String,
    typefontfamily: String,
    typefontsize: String,
    typefontstyle: String,
    typefontweight: String,
    typelineheight: String,

    desccolor: String,
    descfontfamily: String,
    descfontsize: String,
    descfontstyle: String,
    descfontweight: String,
    desclineheight: String,
  },
  computed: {
    CardStyle() {
      return {
        border: " 0.4px solid #d4d4d4",
        width: this.cardWidth,
        height: this.cardHeight,
      };
    },
    TitleStyle() {
      return {
        color: this.titlecolor,
        "font-family": this.titlefontfamily,
        "font-size": this.titlefontsize,
        "font-style": this.titlefontstyle,
        "font-weight": this.titlefontweight,
        "line-height": this.titlelineheight,
      };
    },
    TypeStyle() {
      return {
        display: "flex",
        color: this.typecolor,
        "margin-top": "4px",
        "justify-content": "flex-start",
        "font-family": this.typefontfamily,
        "font-size": this.typefontsize,
        "font-style": this.typefontstyle,
        "font-weight": this.typefontweight,
        "line-height": this.typelineheight,
      };
    },

    DescStyle() {
      return {
        display: "flex",
        color: this.desccolor,
        "font-family": this.descfontfamily,
        "font-size": this.descfontsize,
        "font-style": this.descfontstyle,
        "font-weight": this.descfontweight,
        "line-height": this.desclineheight,
      };
    },
  },
  mounted() {
    formatDate();
  },
  methods: {
    formatDate,
    passEvent(item) {
      this.$emit("action", item);
    },
    async disableSelectedItem(selectedValue, column_id) {
      let related_json = {
        column_id: column_id,
        column_field: selectedValue,
      };
      related_json = JSON.stringify(related_json);

      this.post_result = await Post(related_json, "saverelatedfields");

      if (this.post_result.success) {
        let old_relation = this.post_result.data.old_relation;
        let founds = 0;
        this.custom_fields.map((field) => {
          if (field.coluna === selectedValue) {
            field.disabled = true;
            founds++;
          } else if (field.coluna === old_relation) {
            field.disabled = false;
            founds++;
          }
          if (founds == 2) {
            return;
          }
        });

        this.snackbar = true;
        this.snackbar_options.text = "Vínculo realizado com sucesso";
        this.snackbar_options.color = "green";
        this.snackbar_options.timeout = 1500;
      } else {
        this.snackbar = true;
        this.snackbar_options.text = "Coluna vinculada a outro campo.";
        this.snackbar_options.color = "red";
        this.snackbar_options.timeout = -1;
      }
    },
  },
};
</script>
