<template>
  <backButton buttonText="Dados" routeLink="/"></backButton>
  <MainCard
    :open_from_errors_pop_up="dialog_status"
    :beta="true"
    :title="CardData[0]['data_category']"
    :last_update="
      Versions?.length > 0 && Versions[0]['updated_at']
        ? Versions[0]['updated_at']
        : none
    "
    btnactivated="1"
  >
    <div class="mt-4 ml-5">
      <TabContents
        @DialogOpening="OpenDialogFromErrorsPopUp"
        @refresh="ReloadHistory"
        :items="Versions"
      >
        <StandardCard
          :items="CardData"
          cardWidth="200px"
          cardHeight="280px"
          titlecolor="#1a1a1a"
          titlefontfamily="Roboto"
          titlefontsize="14px"
          titlefontstyle="normal"
          titlefontweight="400"
          titlelineheight="normal"
          typeecolor="#4d4d4d"
          typeontfamily="Roboto"
          typefontsize="12px"
          typefontstyle="normal"
          typefontweight="400"
          typelineheight="normal"
          desccolor="#808080"
          descfontfamily="Roboto"
          descfontsize="12px"
          descfontstyle="normal"
          descfontweight="400px"
          desclineheight="normal"
          lg="3"
          xlg="3"
          :autocomplete_activated="true"
        ></StandardCard>
      </TabContents>
    </div>
  </MainCard>
</template>

<script>
import MainCard from "@/components/MainCard.vue";
import BackButton from "@/components/BackButton.vue";
import TabContents from "@/components/TabContents.vue";
import StandardCard from "@/components/StandardCard.vue";
import { Get } from "@/http/http";

export default {
  data() {
    return {
      dialog_status: false,
      CardData: this.$route.meta.data_types,
      Versions: this.$route.meta.data_versions,
    };
  },
  name: "DataView",
  components: {
    BackButton,
    MainCard,
    TabContents,
    StandardCard,
  },
  methods: {
    OpenDialogFromErrorsPopUp(value) {
      this.dialog_status = false;
      setTimeout(() => {
        this.dialog_status = value;
      }, 0);
    },
    async ReloadHistory() {
      this.Versions = await Get("listversions/" + this.$route.params.id);
    },
  },
};
</script>
