<template>
  <v-img cover height="100vh" src="../assets/404.svg"></v-img>
</template>

<script>
export default {
  name: "404Page",
  components: {},
};
</script>
