<template>
  <v-tabs v-model="tab" bg-color="white" slider-color="#0DA6A6">
    <v-tab value="one">Definições</v-tab>
    <v-tab value="two">Histórico</v-tab>
  </v-tabs>

  <div
    style="
      background-color: red;
      height: 1px;
      margin-left: -50px;
      margin-right: -50px;
      margin-bottom: -8px;
      background-color: #d4d4d4 !important;
      fill: none;
      flex-shrink: 0;
    "
  ></div>

  <v-card-text style="padding: 22px; margin-right: -38px">
    <v-window v-model="tab" class="ml-n9">
      <v-window-item
        :transition="false"
        :reverse-transition="false"
        value="one"
        class="mt-n5"
      >
        <div class="ml-4 d-flex mb-n1 flex-row justify-space-between">
          <div
            class="d-flex justify-space-evenly"
            style="
              width: 250px;
              height: 40px;
              flex-shrink: 0;
              border-radius: 4px;
              background: #f2f2f2;
            "
          >
            <v-text class="options"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="10" cy="10" r="10" fill="#004747" />
              </svg>

              <p>Obrigatório</p>
            </v-text>
            <v-text class="options">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="9"
                  fill="#D9D9D9"
                  stroke="#004747"
                  stroke-width="2"
                />
              </svg>
              <p>Opcional</p>
            </v-text>
          </div>
        </div>

        <slot></slot>
      </v-window-item>

      <v-window-item
        :transition="false"
        :reverse-transition="false"
        value="two"
      >
        <v-card
          class="mt-2 ml-5 mr-6"
          style="width: calc(100vw - 148px); border: none; box-shadow: none"
        >
          <div class="flex-row justify-space-between">
            <v-col cols="12" md="4" sm="5">
              <v-card-title
                class="mb-n4 mt-n3 ml-n1 d-flex justify-space-between"
                style="height: 70px"
              >
                <v-text-field
                  prepend-inner-icon="mdi-magnify"
                  v-model="search"
                  placeholder="Pesquisar"
                  clearable
                >
                  <template v-slot:clear-icon>
                    <font-awesome-icon
                      :icon="['fas', 'circle-xmark']"
                    /> </template
                ></v-text-field>

                <v-btn
                  @click="RefreshHandler"
                  :ripple="false"
                  variant="plain"
                  style="margin-left: -5px; margin-top: 10px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="17"
                    fill="none"
                  >
                    <path
                      d="M12.7575 0.745152C12.527 0.649232 12.2617 0.701541 12.0851 0.878114L10.4896 2.47369C7.93049 0.617618 4.32412 0.834502 2.01908 3.13961L1.36627 3.79245C0.86489 4.29376 0.86489 5.10788 1.36627 5.60925C1.61692 5.85991 1.94604 5.98525 2.27411 5.98525C2.6033 5.98525 2.93184 5.85994 3.1825 5.60925L3.83537 4.95638C5.13503 3.65622 7.11587 3.44914 8.63786 4.32648L7.20471 5.75853C7.0281 5.93507 6.97583 6.20099 7.0706 6.43099C7.16656 6.66206 7.39157 6.81245 7.64118 6.81245H12.5211C12.8622 6.81245 13.1385 6.5356 13.1385 6.19553V1.31515C13.1384 1.06557 12.9886 0.839962 12.7575 0.745152Z"
                      fill="#808080"
                    />
                    <path
                      d="M10.8468 11.2242L10.1945 11.877C9.44301 12.629 8.44258 13.0432 7.37939 13.0432C6.66932 13.0432 5.98928 12.8546 5.3909 12.508L6.8246 11.0738C7.00171 10.8972 7.05405 10.6324 6.9587 10.4014C6.86278 10.1714 6.63774 10.021 6.38813 10.021H1.50826C1.16714 10.021 0.890869 10.2968 0.890869 10.6379V15.5183C0.890869 15.7678 1.04126 15.9924 1.27176 16.0883C1.50226 16.1842 1.76815 16.1308 1.94472 15.9542L3.53814 14.3609C4.64925 15.1684 5.97887 15.612 7.37939 15.612C9.12916 15.612 10.7738 14.9308 12.0114 13.6928L12.6636 13.041C13.1649 12.5386 13.1649 11.7256 12.6636 11.2242C12.1617 10.7229 11.3481 10.7229 10.8468 11.2242Z"
                      fill="#808080"
                    />
                  </svg>
                </v-btn> </v-card-title
            ></v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              id="paginator"
              style="margin-bottom: 150px; margin-top: -70px"
            ></v-col>
          </div>
          <v-data-table
            style="margin-top: -120px"
            @vue:mounted="Adjust"
            :items="items"
            :search="search"
            page-text="{0}-{1} de {2}"
            items-per-page="5"
            items-per-page-text="Itens por página:"
            :items-per-page-options="[
              2,
              5,
              10,
              20,
              30,
              40,
              50,
              { value: -1, title: 'Todos' },
            ]"
          >
            <template v-slot:item="{ item }">
              <HistoryTable
                :key="componentKey"
                @DialogStatus="Dialoghandler"
                :item="item"
              ></HistoryTable>
            </template>
          </v-data-table>

          <p
            class="no-data"
            v-if="items?.length == 0 || typeof items == 'undefined'"
          >
            Sem dados disponíveis
          </p>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card-text>
</template>

<style>
.v-tab {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: Roboto !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}

.options {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  color: #4d4d4d;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 0.5rem;
}

.v-text-field input {
  opacity: 5;
  background-color: white;
}

.v-divider {
  display: none;
}

.v-field--variant-filled .v-field__overlay {
  background-color: white !important;
}

tr {
  display: none;
}

.v-data-table-footer {
  margin-left: 20px;
}

.no-data {
  font-family: Poppins !important;
  font-size: 22px;
  font-weight: 500;
  color: #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(50vh - 350px);
  padding: 20px;
}
</style>
<script>
import HistoryTable from "./HistoryTable.vue";

export default {
  components: { HistoryTable },
  data() {
    return {
      tab: "null",
      search: "",
      total_items: [],
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    Adjust() {
      document
        .getElementById("paginator")
        ?.append(document.getElementsByClassName("v-data-table-footer")[0]);
    },
    Dialoghandler(value) {
      this.$emit("DialogOpening", value);
    },
    RefreshHandler(value) {
      this.$emit("refresh", value);
    },
  },
};
</script>
