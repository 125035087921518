<template>
  <v-dialog
    :model-value="openWarning"
    @update:model-value="updateWarning"
    :persistent="false"
    max-width="403"
    max-height="308"
  >
    <v-card>
      <v-card-title
        style="
          margin-top: 15px;
          margin-left: 10px;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          color: #1a1a1a;
        "
        class="headline"
        >Atenção</v-card-title
      >
      <div class="dialog-text">
        <p>Esta ação não pode ser desfeita, deseja realmente alterar a aplicação?</p>
        <p>
          Ao alterar uma aplicação não será possível utilizar a que está
          aplicada atualmente.
        </p>
      </div>

      <v-card-actions class="errors-actions">
        <v-spacer></v-spacer>
        <v-btn
          @click="updateWarning(false)"
          style="
            text-transform: none;
            padding: 9px;
            opacity: 0px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
          "
          >Cancelar</v-btn
        >
        <v-btn
          @click="ChangeScreen(false)"
          style="
            color: white;
            background-color: #0da6a6;
            text-transform: none;
            padding: 9px 9px;
            border-radius: 4px;
            opacity: 0px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
          "
          >Alterar aplicação</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Patch } from "@/http/http";

export default {
  name: "WarningPopup",
  props: {
    openWarning: Boolean,
  },
  methods: {
    updateWarning(value) {
      this.$emit("close-warning", value);
    },
    ChangeScreen(value) {
      this.$emit("change-screen", value);
      this.$emit("change-switch-status", "Desativado", false);
      Patch("deleteconfig");
    },
  },
};
</script>

<style>
.dialog-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  margin-top: 22px;
  margin-left: 28px;
  margin-right: 21px;
}
</style>
