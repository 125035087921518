<template>
  <backButton buttonText="Modelos" routeLink="/"></backButton>
  <LoadingStatus v-if="val_loading_status == 1"></LoadingStatus>
  <v-col
    class="ml-0 v-100 h-100"
    style="
      background-color: #fff;
      border-radius: 5px 0px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
    "
  >
    <v-row class="d-flex flex-column text-left" style="margin: 0">
      <v-col
        class="d-flex justify-space-between pl-8 pt-5 text-h5"
        style="
          color: #1a1a1a;
          font-family: Poppins;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            margin-top: -40px;
            gap: 20px;
          "
          class="dv"
        >
          Permanência de alunos
          <v-chip
            class="beta-chip"
            variant="tonal"
            border="0"
            color="#7dcfcf"
            rounded="lg"
            text="BETA"
          ></v-chip>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 32px;
            height: 100%;
          "
        >
          <v-switch
            @click="toggleSwitchStatus"
            v-model="switch_.status"
            color="#009688"
            :label="switch_.label"
          ></v-switch>
          <v-text
            v-if="application_data?.updated_at"
            style="
              margin-top: -28px;
              margin-bottom: 20px;
              color: #4d4d4d;
              font-family: Roboto;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
            "
          >
            Última atualização:
            {{ formatDate(application_data?.updated_at) }}
          </v-text>
        </div>
      </v-col>
      <div class="ml-5 mt-n8">
        <v-tabs v-model="tab" bg-color="white" slider-color="#0DA6A6">
          <v-tab value="one">Treinamento</v-tab>
          <v-tab value="two">Validação</v-tab>
          <v-tab value="three">Aplicação</v-tab>
        </v-tabs>
        <div
          style="
            background-color: red;
            height: 1px;
            margin-left: -50px;
            margin-right: -50px;
            background-color: #d4d4d4 !important;
            fill: none;
            flex-shrink: 0;
          "
        ></div>

        <v-card-text
          style="
            padding: 22px;
            margin-right: -38px;
            margin-top: -20px;
            margin-bottom: -25px;
          "
        >
          <v-window v-model="tab" class="ml-n9">
            <v-window-item
              :transition="false"
              :reverse-transition="false"
              value="one"
              class="mt-n5"
            >
              <div class="train-wrapper">
                <v-col cols="12" md="5" lg="6" class="first-part">
                  <div class="first-part-header">
                    <p>Histórico</p>
                    <v-btn
                      @click="RefreshHandler"
                      :ripple="false"
                      variant="plain"
                      style="margin-left: 32px; margin-top: 10px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="17"
                        fill="none"
                      >
                        <path
                          d="M12.7575 0.745152C12.527 0.649232 12.2617 0.701541 12.0851 0.878114L10.4896 2.47369C7.93049 0.617618 4.32412 0.834502 2.01908 3.13961L1.36627 3.79245C0.86489 4.29376 0.86489 5.10788 1.36627 5.60925C1.61692 5.85991 1.94604 5.98525 2.27411 5.98525C2.6033 5.98525 2.93184 5.85994 3.1825 5.60925L3.83537 4.95638C5.13503 3.65622 7.11587 3.44914 8.63786 4.32648L7.20471 5.75853C7.0281 5.93507 6.97583 6.20099 7.0706 6.43099C7.16656 6.66206 7.39157 6.81245 7.64118 6.81245H12.5211C12.8622 6.81245 13.1385 6.5356 13.1385 6.19553V1.31515C13.1384 1.06557 12.9886 0.839962 12.7575 0.745152Z"
                          fill="#808080"
                        />
                        <path
                          d="M10.8468 11.2242L10.1945 11.877C9.44301 12.629 8.44258 13.0432 7.37939 13.0432C6.66932 13.0432 5.98928 12.8546 5.3909 12.508L6.8246 11.0738C7.00171 10.8972 7.05405 10.6324 6.9587 10.4014C6.86278 10.1714 6.63774 10.021 6.38813 10.021H1.50826C1.16714 10.021 0.890869 10.2968 0.890869 10.6379V15.5183C0.890869 15.7678 1.04126 15.9924 1.27176 16.0883C1.50226 16.1842 1.76815 16.1308 1.94472 15.9542L3.53814 14.3609C4.64925 15.1684 5.97887 15.612 7.37939 15.612C9.12916 15.612 10.7738 14.9308 12.0114 13.6928L12.6636 13.041C13.1649 12.5386 13.1649 11.7256 12.6636 11.2242C12.1617 10.7229 11.3481 10.7229 10.8468 11.2242Z"
                          fill="#808080"
                        />
                      </svg>
                    </v-btn>
                  </div>
                  <div
                    class="no-data"
                    v-if="versions_post_response?.length === 0"
                  >
                    Sem dados disponíveis
                  </div>
                  <div
                    class="hr-gap"
                    v-for="(response, index) in versions_post_response"
                    :key="index"
                  >
                    <v-card style="border: 1px solid #d4d4d4">
                      <v-card-actions>
                        <div class="loading-train-items">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: flex-start;
                            "
                          >
                            <p class="train-title">
                              {{ response?.train_name }}
                            </p>
                            <p class="train-date">
                              {{
                                formatDate(response?.updated_at, (status = 1))
                              }}
                              por
                              {{ response?.user_name }}
                            </p>
                          </div>
                          <v-chip
                            class="training-chip"
                            color="white"
                            v-if="response?.train_status == 2"
                          >
                            <template v-slot:prepend>
                              <font-awesome-icon
                                class="fa-spin"
                                :icon="['fas', 'circle-notch']" /></template
                            >Em treinamento</v-chip
                          >
                          <v-chip
                            class="error-chip"
                            v-if="response?.train_status == 4"
                            color="white"
                          >
                            <template v-slot:prepend>
                              <font-awesome-icon
                                :icon="['fas', 'circle-xmark']"
                              />
                            </template>
                            Erro no treinamento</v-chip
                          >

                          <v-chip
                            class="training-waiting-chip"
                            color="white"
                            v-if="response?.train_status == 1"
                          >
                            <template v-slot:prepend>
                              <font-awesome-icon
                                class="fa-fade"
                                :icon="['far', 'clock']" /></template
                            >Aguardando</v-chip
                          >

                          <v-tooltip
                            text="Índice de acerto"
                            v-if="response?.train_status == 3"
                          >
                            <template v-slot:activator="{ props }">
                              <div v-bind="props" class="circle-score">
                                <p>
                                  {{
                                    formatNumber(response?.train_prediction)
                                  }}%
                                </p>
                              </div>
                            </template>
                          </v-tooltip>
                        </div>

                        <v-spacer></v-spacer>

                        <v-btn
                          :icon="
                            showCards[index]
                              ? 'mdi-chevron-up'
                              : 'mdi-chevron-down'
                          "
                          @click="ToogleCard(index)"
                        ></v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <div v-show="showCards[index]">
                          <v-divider></v-divider>
                          <div style="margin-bottom: 10px">
                            <v-card-text
                              v-for="(
                                correlation, i
                              ) in response?.data_correlations"
                              :key="i"
                              class="data-version-text"
                            >
                              {{
                                correlation.data_type
                                  ? correlation?.data_type + ":"
                                  : ""
                              }}
                              {{ correlation?.version || "" }}
                              <div style="margin-top: -20px"></div>
                            </v-card-text>
                          </div>
                        </div>
                      </v-expand-transition>
                    </v-card>
                    <div class="hr"></div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  lg="6"
                  class="second-part-wrapper ml-md-n4 ml-lg-n6"
                >
                  <div
                    class="training-loading-container"
                    v-if="loading_status == 1"
                  >
                    <img
                      class="training-loading-image"
                      src="https://arquivos.apprbs.com.br/Rubeus/rbGif.gif"
                    />
                    <p class="training-loading-text">
                      Aguarde, este processo pode demorar <br />
                      alguns segundos...
                    </p>
                  </div>

                  <div class="second-part" v-if="loading_status == 0">
                    <p class="second-part-first-text">
                      Para realizar o treinamento do modelo, selecione as
                      versões das bases de dados listadas abaixo. Dê um nome
                      para a versão do treinamento.
                    </p>
                    <v-form
                      class="training-form"
                      ref="version_form"
                      v-model="version_form_status"
                    >
                      <v-text-field
                        class="train-title-input"
                        label="Título do treinamento*"
                        variant="outlined"
                        v-model="train_title"
                        :rules="Rules"
                      ></v-text-field>

                      <div
                        class="d-flex flex-row justify-space-between"
                        style="
                          width: 80%;
                          margin-top: 40px;
                          margin-bottom: 22px;
                        "
                      >
                        <div
                          class="d-flex justify-space-evenly"
                          style="
                            width: 250px;
                            height: 40px;
                            flex-shrink: 0;
                            border-radius: 4px;
                            background: #f2f2f2;
                          "
                        >
                          <v-text class="options"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <circle cx="10" cy="10" r="10" fill="#004747" />
                            </svg>

                            <p>Obrigatório</p>
                          </v-text>
                          <v-text class="options">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <circle
                                cx="10"
                                cy="10"
                                r="9"
                                fill="#D9D9D9"
                                stroke="#004747"
                                stroke-width="2"
                              />
                            </svg>
                            <p>Opcional</p>
                          </v-text>
                        </div>
                      </div>

                      <div
                        class="second-part-cards"
                        v-for="(content, index) in available_versions"
                        :key="content"
                      >
                        <div
                          class="container d-flex flex-column"
                          style="height: 150px"
                        >
                          <div class="second-part-data-text">
                            <p class="title">{{ content.data_type }}</p>
                            <v-sheet>
                              <v-img
                                width="18px"
                                :src="require('../assets/obrigatorio.svg')"
                              ></v-img
                            ></v-sheet>
                          </div>
                          <v-select
                            no-data-text="Sem dados disponíveis"
                            :rules="requiredRule"
                            class="second-part-select"
                            label="Versão*"
                            item-title="title"
                            item-value="version"
                            :items="content.versions"
                            v-model="selected_version[index]"
                            variant="solo-filled"
                            @update:model-value="
                              SaveSelectedVersion(
                                content,
                                selected_version[index],
                                index
                              )
                            "
                          ></v-select>
                        </div></div
                    ></v-form>
                  </div>
                  <div class="button-area" v-if="loading_status == 0">
                    <v-btn
                      :disabled="
                        !version_form_status || available_versions.length === 0
                      "
                      @click="TrainModel"
                      variant="flat"
                      style="
                        margin-top: 28px;
                        margin-right: 27px;
                        display: inline-flex;
                        padding: 9px;
                        align-items: center;
                        color: white;
                        border-radius: 4px;
                        background: #0da6a6;
                        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25),
                          0px 1px 2px 0px rgba(0, 0, 0, 0.12),
                          0px 1px 1px 0px rgba(0, 0, 0, 0.1);
                        text-align: center;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 17.807px;
                        text-transform: none;
                      "
                      ><svg
                        style="margin-right: 4px"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5623 1.31676L7.93971 3.93937L8.29327 5.70714L10.061 6.06069L12.6837 3.43808L12.6839 3.43797C13.0085 4.20424 13.0862 5.05265 12.906 5.86511C12.7259 6.67757 12.2969 7.41365 11.6788 7.97089C11.0607 8.52813 10.2843 8.8788 9.45757 8.9741C8.63085 9.0694 7.79499 8.90457 7.06634 8.50257L7.06641 8.50244L3.56103 12.5607C3.27967 12.8417 2.89823 12.9995 2.50055 12.9994C2.10287 12.9993 1.72152 12.8413 1.44032 12.5601C1.15912 12.2789 1.00109 11.8975 1.00098 11.4999C1.00086 11.1022 1.15867 10.7207 1.43971 10.4394L5.49792 6.93403L5.49784 6.93408C5.09583 6.20542 4.93101 5.36956 5.02631 4.54284C5.1216 3.71613 5.47228 2.93969 6.02952 2.3216C6.58676 1.70352 7.32284 1.27455 8.1353 1.0944C8.94776 0.914249 9.79617 0.991886 10.5624 1.3165L10.5623 1.31676Z"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Treinar modelo</v-btn
                    >
                  </div>
                </v-col>
              </div>
            </v-window-item>
            <v-window-item
              :transition="false"
              :reverse-transition="false"
              value="two"
              class="mt-n5"
              ><div class="validation-container">
                <p class="validation-header">
                  Dados utilizados para a validação do modelo representam cerca
                  de 0.5% das bases de dados selecionadas para o treinamento.
                </p>

                <v-select
                  class="validation-select"
                  no-data-text="Nenhum modelo disponível"
                  label="Título do treinamento*"
                  item-title="train_name"
                  item-value="train_id"
                  v-model="selected_training"
                  :items="versions_post_response"
                  variant="outlined"
                  @update:model-value="selectedTrainingHandler"
                ></v-select>
                <p
                  style="
                    margin-top: 24px;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    color: #4d4d4d;
                  "
                >
                  Resultado
                </p>

                <div
                  class="no-results-visualization"
                  v-if="!validation_object || validation_object.length === 0"
                >
                  <h2>Nenhum treinamento selecionado.</h2>
                  <p>
                    Para visualizar o resultado, é necessário selecionar um
                    treinamento previamente!
                  </p>
                </div>

                <div
                  class="no-results-visualization"
                  v-if="
                    validation_object &&
                    validation_object[0] &&
                    validation_object[0].validation_set.length === 0 &&
                    validation_object[0].train_status != 3
                  "
                >
                  <div style="display: flex; align-items: center; gap: 15px">
                    <h2>Situação do treinamento:</h2>
                    <v-chip
                      v-if="validation_object[0]?.train_status == 1"
                      style="margin-top: -18px"
                      class="training-waiting-chip"
                      color="white"
                    >
                      <template v-slot:prepend>
                        <font-awesome-icon
                          class="fa-fade"
                          :icon="['far', 'clock']" /></template
                      >Aguardando</v-chip
                    >
                    <v-chip
                      style="margin-top: -18px"
                      class="error-chip"
                      v-if="validation_object[0]?.train_status == 4"
                      color="white"
                    >
                      <template v-slot:prepend>
                        <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                      </template>
                      Erro no treinamento</v-chip
                    >
                    <v-chip
                      v-if="validation_object[0]?.train_status == 2"
                      style="margin-top: -18px"
                      class="training-chip"
                      color="white"
                    >
                      <template v-slot:prepend>
                        <font-awesome-icon
                          class="fa-spin"
                          :icon="['fas', 'circle-notch']" /></template
                      >Em treinamento</v-chip
                    >
                  </div>

                  <p>
                    Para visualizar o resultado, é necessário que o treinamento
                    seja realizado por completo, no momento o treinamento do
                    modelo não foi finalizado. Tente novamente após a conclusão.
                  </p>
                </div>

                <div
                  class="results-visualization"
                  v-if="
                    validation_object &&
                    validation_object[0] &&
                    validation_object[0].validation_set.length > 0
                  "
                >
                  <v-data-table
                    class="validation-table"
                    fixed-header
                    :headers="[
                      { title: 'Nome do aluno', key: 'NOMEALUNO' },
                      { title: 'Curso', key: 'CURSO' },
                      { title: 'Modalidade', key: 'MODALIDADECURSO' },
                      { title: 'Local de Oferta', key: 'CIDADEIES' },
                      { title: 'Evadido', key: 'EVASAO' },
                      {
                        title: 'Probabilidade',
                        key: 'SCORE_FINAL',
                      },
                    ]"
                    :items="validation_object[0].validation_set"
                    :items-per-page="100"
                    page-text="{0}-{1} de {2}"
                    items-per-page-text="Itens por página:"
                    :items-per-page-options="[
                      2,
                      5,
                      10,
                      20,
                      30,
                      40,
                      50,
                      { value: -1, title: 'Todos' },
                    ]"
                  >
                    <template
                      v-slot:headers="{
                        columns,
                        isSorted,
                        getSortIcon,
                        toggleSort,
                      }"
                    >
                      <tr>
                        <template v-for="column in columns" :key="column.key">
                          <th>
                            <span
                              style="display: flex"
                              class="mr-2 cursor-pointer"
                              @click="() => toggleSort(column)"
                              >{{ column.title }}
                              <v-tooltip
                                v-if="column.title == 'Probabilidade'"
                                max-width="300px"
                                max-height="150px"
                                text="Probabilidade de evasão baseada no registro do aluno."
                              >
                                <template v-slot:activator="{ props }">
                                  <v-icon
                                    v-bind="props"
                                    color="white"
                                    style="
                                      margin-left: 5px;
                                      margin-bottom: 2px;
                                      background-color: #757575;
                                      padding: 10px;
                                      font-size: 15px;
                                      border-radius: 100%;
                                    "
                                    icon="mdi-exclamation-thick"
                                  ></v-icon> </template></v-tooltip
                            ></span>
                            <template v-if="isSorted(column)">
                              <v-icon :icon="getSortIcon(column)"></v-icon>
                            </template>
                          </th>
                        </template>
                      </tr>
                    </template>
                  </v-data-table>

                  <v-progress-circular
                    v-if="validation_object != null"
                    class="score-progress-bar"
                    color="#004747"
                    :model-value="validation_object[0].val_prediction"
                    :size="250"
                    :width="20"
                  >
                    <div
                      score-visualization-text
                      v-if="validation_object != null"
                    >
                      <p class="score-number">
                        {{
                          formatNumber(
                            this.validation_object[0].val_prediction
                          )
                        }}%
                      </p>
                      <p class="score-text">Precisão</p>
                    </div></v-progress-circular
                  >
                </div>
              </div></v-window-item
            >
            <v-window-item
              value="three"
              :transition="false"
              :reverse-transition="false"
            >
              <div class="three-part-header">
                <p class="three-part-main-text">
                  Receba nos registros dos seus processos de permanência de
                  alunos, a probabilidade de evasão através da nossa
                  inteligência artificial.
                </p>
                <div v-if="buttonClicked" style="margin-right: 44px">
                  <v-btn
                    @click="WarningStatusHandler(true)"
                    elevation="4"
                    class="change-application-config-btn"
                    prepend-icon="mdi-cog"
                    >Alterar aplicação</v-btn
                  >
                  <WarningPopup
                    @change-switch-status="ChangeSwitchStatusWarning"
                    @change-screen="ChangeValue"
                    :openWarning="warning_status"
                    @close-warning="WarninigHandler"
                  ></WarningPopup>
                </div>
              </div>

              <div v-if="!buttonClicked" class="application-form-container">
                <v-form
                  ref="application_form"
                  v-model="application_form_status"
                  class="application-form"
                >
                  <v-card
                    style="
                      border: 1px solid #d4d4d4;
                      margin-left: 32px;
                      margin-bottom: 24px;
                    "
                  >
                    <v-card-actions>
                      <div class="identification-group">
                        <p class="label-name">Identificação</p>
                        <v-text-field
                          class="segmentation-field"
                          label="Título da segmentação*"
                          variant="solo-filled"
                          v-model="formData.segmentation_name"
                          :rules="Rules"
                        ></v-text-field>
                      </div>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="expand-button"
                        :icon="show ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                        @click="show = !show"
                      ></v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <div v-show="!show">
                        <v-divider></v-divider>
                        <div class="model-group">
                          <p class="label-name">Modelo Aplicado</p>
                          <div class="model-content">
                            <v-select
                              label="Versão de treinamento do modelo*"
                              no-data-text="Nenhum modelo disponível"
                              variant="solo-filled"
                              item-title="train_name"
                              item-value="train_id"
                              :rules="requiredRule"
                              :items="filteredTrainings"
                              v-model="formData.training_id"
                              @change="changeStyle"
                            ></v-select>
                            <div
                              style="margin-top: 8px; visibility: hidden"
                              id="progress-div"
                            >
                              <v-tooltip text="Índice de acerto">
                                <template v-slot:activator="{ props }">
                                  <div v-bind="props" class="circle-score">
                                    <p>
                                      {{
                                        getSelectedTrainName(
                                          formData.training_id
                                        )
                                      }}%
                                    </p>
                                  </div>
                                </template>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>

                        <div class="capitation-process">
                          <p class="label-name">Processos de captação</p>
                          <v-select
                            chips
                            multiple
                            style="width: 480px"
                            label="Processos*"
                            variant="solo-filled"
                            item-title="nome"
                            item-value="id"
                            :items="crm_process_response"
                            :rules="requiredRule"
                            v-model="formData.process"
                            required
                          ></v-select>
                        </div>

                        <div class="exceptional-steps">
                          <p class="label-name">Etapas excepcionais</p>
                          <p
                            style="
                              font-family: Roboto;
                              font-size: 14px;
                              font-style: italic;
                              font-weight: 400;
                              color: #808080;
                              margin-top: -10px;
                              margin-bottom: 16px;
                            "
                          >
                            Registros nessas etapas não necessitam de análise,
                            recebendo probabilidades fixas
                          </p>
                          <div class="expectional-steps-content">
                            <div class="select1">
                              <v-select
                                no-data-text="Selecione um processo"
                                chips
                                multiple
                                style="width: 344px"
                                label="Etapas de evasão"
                                variant="solo-filled"
                                item-title="name"
                                item-value="id"
                                v-model="formData.dropout_steps"
                                :items="filteredDropoutSteps"
                                :rules="requiredRule"
                                item-props="itemProps"
                              ></v-select>

                              <p
                                style="
                                  font-family: Roboto;
                                  margin-top: -15px;
                                  font-size: 12px;
                                  font-weight: 400;
                                  color: #00000099;
                                "
                              >
                                Probabilidade de evasão: 100%
                              </p>
                            </div>
                            <div class="select2">
                              <v-select
                                no-data-text="Selecione um processo"
                                chips
                                multiple
                                style="width: 344px"
                                label="Etapas de conclusão"
                                item-title="name"
                                item-value="id"
                                v-model="formData.conclusion_steps"
                                :items="filteredConclusionSteps"
                                :rules="requiredRule"
                                variant="solo-filled"
                                item-props="itemProps"
                              ></v-select>
                              <p
                                style="
                                  font-family: Roboto;
                                  font-size: 12px;
                                  font-weight: 400;
                                  color: #00000099;
                                  margin-top: -15px;
                                "
                              >
                                Probabilidade de evasão: 0%
                              </p>
                            </div>
                          </div>

                          <div class="target-audience">
                            <p class="label-name">
                              Público alvo
                              <v-tooltip
                                max-width="300px"
                                max-height="150px"
                                text="Selecione as opções que irão delimitar o público alvo da análise. "
                              >
                                <template v-slot:activator="{ props }">
                                  <v-icon
                                    v-bind="props"
                                    color="white"
                                    style="
                                      margin-left: 11px;
                                      margin-bottom: 2px;
                                      background-color: #757575;
                                      padding: 10px;
                                      font-size: 12px;
                                      border-radius: 100%;
                                    "
                                    icon="mdi-exclamation-thick"
                                  ></v-icon>
                                </template>
                              </v-tooltip>
                            </p>
                            <v-select
                              chips
                              multiple
                              style="width: 344px"
                              label="Nível de ensino"
                              variant="solo-filled"
                              :items="crm_edulevel_response"
                              v-model="formData.education_level"
                              item-title="titulo"
                              item-value="id"
                              :rules="requiredRule"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  title="Todos"
                                  @click="toggleSelectAllEducation"
                                >
                                  <template v-slot:prepend>
                                    <v-checkbox-btn
                                      :model-value="isSelectAlleducation"
                                      :color="
                                        isSelectedSomeEducation
                                          ? 'indigo-darken-4'
                                          : undefined
                                      "
                                      :indeterminate="
                                        isSelectedSomeEducation &&
                                        !isSelectAlleducation
                                      "
                                    ></v-checkbox-btn>
                                  </template>
                                </v-list-item>

                                <v-divider class="mt-2"></v-divider> </template
                            ></v-select>
                            <v-select
                              chips
                              multiple
                              style="width: 344px"
                              label="Modalidade"
                              variant="solo-filled"
                              v-model="formData.modality"
                              :items="crm_modality_response"
                              item-title="titulo"
                              item-value="id"
                              :rules="requiredRule"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  title="Todos"
                                  @click="toggleSelectAllModality"
                                >
                                  <template v-slot:prepend>
                                    <v-checkbox-btn
                                      :model-value="isSelectAllmodality"
                                      :color="
                                        isSelectedSomeModality
                                          ? 'indigo-darken-4'
                                          : undefined
                                      "
                                      :indeterminate="
                                        isSelectedSomeModality &&
                                        !isSelectAllmodality
                                      "
                                    ></v-checkbox-btn>
                                  </template>
                                </v-list-item>

                                <v-divider class="mt-2"></v-divider> </template
                            ></v-select>
                            <v-select
                              chips
                              multiple
                              label="Locais de Oferta"
                              style="width: 344px"
                              variant="solo-filled"
                              v-model="formData.offers"
                              :items="crm_offer_response"
                              item-title="titulo"
                              item-value="id"
                              :rules="requiredRule"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  title="Todos"
                                  @click="toggleSelectAll"
                                >
                                  <template v-slot:prepend>
                                    <v-checkbox-btn
                                      :model-value="isSelectAll"
                                      :color="
                                        isSelectSomeOptions
                                          ? 'indigo-darken-4'
                                          : undefined
                                      "
                                      :indeterminate="
                                        isSelectSomeOptions && !isSelectAll
                                      "
                                    ></v-checkbox-btn>
                                  </template>
                                </v-list-item>

                                <v-divider class="mt-2"></v-divider>
                              </template>
                              ></v-select
                            >
                          </div>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-card></v-form
                >
              </div>
              <v-btn
                v-if="!buttonClicked"
                @click="ChangeValue(true)"
                elevation="4"
                class="add-seg-btn"
                prepend-icon="mdi-plus"
                >Adicionar segmentação</v-btn
              >
              <div v-if="!buttonClicked" class="floating-bar">
                <div class="btns-field">
                  <v-btn
                    @click="submitForm"
                    style="
                      color: white;
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 17.807px;
                      text-transform: none;
                      border-radius: 4px;
                      background: #0da6a6;
                    "
                    :disabled="!application_form_status"
                    >Aplicar Análise</v-btn
                  >
                </div>
              </div>

              <div v-if="buttonClicked" class="aplication-view">
                <v-col cols="12" md="6" lg="6" class="ml-md-n3 ml-lg-n5">
                  <p class="small-label-size">Modelo aplicado</p>
                  <div style="margin-bottom: 24px">
                    <v-card style="border: 1px solid #d4d4d4">
                      <v-card-actions>
                        <div class="loading-train-items">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: flex-start;
                            "
                          >
                            <p class="train-title">
                              {{ applied_training?.train_name }}
                            </p>
                            <p class="train-date">
                              {{ formatDate(applied_training?.updated_at, 1) }}
                              por {{ applied_training?.user_name }}
                            </p>
                          </div>

                          <v-tooltip text="Índice de acerto">
                            <template v-slot:activator="{ props }">
                              <div v-bind="props" class="circle-score">
                                <p>
                                  {{
                                    formatNumber(
                                      applied_training?.train_prediction
                                    )
                                  }}%
                                </p>
                              </div>
                            </template>
                          </v-tooltip>
                        </div>

                        <v-spacer></v-spacer>

                        <v-btn
                          :icon="
                            application_model_show_details
                              ? 'mdi-chevron-up'
                              : 'mdi-chevron-down'
                          "
                          @click="
                            application_model_show_details =
                              !application_model_show_details
                          "
                        ></v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <div v-show="application_model_show_details">
                          <v-divider></v-divider>
                          <div style="margin-bottom: 10px">
                            <v-card-text
                              v-for="(
                                correlation, i
                              ) in applied_training?.data_correlations"
                              :key="i"
                              class="data-version-text"
                            >
                              {{
                                correlation?.data_type
                                  ? correlation.data_type + ":"
                                  : ""
                              }}
                              {{ correlation?.version || "" }}
                              <div style="margin-top: -20px"></div>
                            </v-card-text>
                          </div>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </div>

                  <p class="small-label-size">Processos</p>

                  <div
                    style="margin-bottom: 24px"
                    v-for="(processo, index) in crm_process_response.filter(
                      (process) => scope.processes?.includes(process.id)
                    )"
                    :key="processo"
                  >
                    <v-card style="border: 1px solid #d4d4d4">
                      <v-card-actions>
                        <div class="loading-train-items">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: flex-start;
                              width: 100%;
                            "
                          >
                            <p class="train-title">{{ processo?.nome }}</p>
                            <div class="container-bar">
                              <div
                                class="bar-content"
                                v-for="etapa in processo?.etapas"
                                :key="etapa"
                              >
                                <div
                                  v-if="
                                    scope.conclusion_steps?.includes(etapa.id)
                                  "
                                  id="pointer-green"
                                  :title="etapa.titulo"
                                ></div>
                                <div
                                  v-else-if="
                                    scope.dropout_steps?.includes(etapa.id)
                                  "
                                  id="pointer-red"
                                  :title="etapa?.titulo"
                                ></div>
                                <div
                                  v-else
                                  id="pointer"
                                  :title="etapa?.titulo"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <v-spacer></v-spacer>

                        <v-btn
                          :icon="
                            showProcesses[index]
                              ? 'mdi-chevron-up'
                              : 'mdi-chevron-down'
                          "
                          @click="ToogleProcess(index)"
                        ></v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <div v-show="showProcesses[index]">
                          <v-divider></v-divider>
                          <div style="margin-left: 8px">
                            <p class="small-label-size">Etapas de evasão</p>
                          </div>
                          <div class="stage-details">
                            <v-ship
                              class="dropout-details"
                              v-for="step in processo.etapas.filter((step) =>
                                scope.dropout_steps.includes(step.id)
                              )"
                              :key="step"
                              >{{ step?.titulo }}</v-ship
                            >
                          </div>
                          <div style="margin-left: 8px">
                            <p class="small-label-size">Etapas de conclusão</p>
                          </div>
                          <div class="stage-details">
                            <v-ship
                              v-for="step in processo.etapas.filter((step) =>
                                scope?.conclusion_steps.includes(step.id)
                              )"
                              :key="step"
                              class="success-details"
                              >{{ step?.titulo }}</v-ship
                            >
                          </div>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </div>
                  <p class="small-label-size">Público alvo</p>
                  <div style="margin-bottom: 24px">
                    <v-card style="border: 1px solid #d4d4d4">
                      <v-card-actions>
                        <div class="loading-train-items">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: flex-start;
                            "
                          >
                            <p class="train-title">Nível de ensino</p>
                            <p
                              style="
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 400;
                                color: #808080;
                                margin-left: 20px;
                              "
                            >
                              {{ applied_education_level?.join(", ") }}
                            </p>
                          </div>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="3" class="right-side">
                  <div class="right-side-container">
                    <p class="small-label-size">Histórico</p>
                    <v-card style="border: 1px solid #d4d4d4">
                      <v-card-actions>
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                          "
                        >
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: flex-start;
                            "
                          >
                            <p class="train-title">Registros atualizados</p>
                            <p
                              class="train-date"
                              v-if="predictions?.length > 0"
                            >
                              {{
                                formatDate(
                                  predictions[predictions?.length - 1]
                                    .updated_at,
                                  (status = 1)
                                )
                              }}
                            </p>
                          </div>
                          <p
                            v-if="predictions?.length > 0"
                            class="updated-number"
                          >
                            {{
                              predictions[predictions?.length - 1].qtd_registers
                            }}
                          </p>
                        </div>

                        <v-spacer></v-spacer>
                      </v-card-actions>

                      <v-expand-transition>
                        <div v-show="showCards[n]">
                          <v-divider></v-divider>
                          <div
                            v-if="
                              typeof predictions == 'undefined' ||
                              predictions?.length == 0
                            "
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              width: 100%;
                              margin: auto;
                              padding-top: 50px;
                              font-size: 20px;
                              font-style: italic;
                              color: #757575;
                            "
                          >
                            Sem dados disponíveis
                          </div>

                          <div
                            v-if="predictions?.length > 0"
                            class="barchart-wrapper"
                          >
                            <BarChart :values="predictions"></BarChart>
                          </div>
                          <div style="margin-bottom: 48px; margin-top: 28px">
                            <div
                              v-for="element in predictions"
                              :key="element"
                              class="chart-content-wrapper"
                            >
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                "
                                v-if="predictions?.length > 0"
                              >
                                <p class="chart-content-text">
                                  {{ element.qtd_registers }}
                                </p>
                                <p class="chart-content-text">
                                  {{
                                    formatDate(element.updated_at, (status = 0))
                                  }}
                                </p>
                              </div>
                              <div class="content-sep"></div>
                            </div>
                          </div>
                        </div>
                      </v-expand-transition>
                      <div
                        style="
                          width: 100%;
                          height: 1px;
                          background-color: #d4d4d4;
                        "
                      ></div>
                      <v-btn
                        variant="text"
                        style="
                          margin-left: 20px;
                          text-transform: none;
                          font-family: Roboto;
                          font-size: 14px;
                          font-weight: 500;
                        "
                        :text="showCards[n] ? 'Ver menos' : 'Ver mais'"
                        @click="ToogleCard(n)"
                      ></v-btn>
                    </v-card>
                  </div>
                </v-col>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import LoadingStatus from "@/components/LoadingStatus.vue";
import WarningPopup from "@/components/WarningPopup.vue";
import BarChart from "@/components/charts/BarChart.vue";
import { PostWithFile, Post } from "@/http/http.js";
import { Get } from "@/http/http.js";
import { formatDate } from "@/utils/dataformatter";

export default {
  data() {
    return {
      available_versions: this.$route.meta.available_versions,
      versions_post_response: this.$route.meta.all_trainings,
      crm_process_response: this.$route.meta.crm_process,
      crm_edulevel_response: this.$route.meta.crm_edulevel,
      crm_modality_response: this.$route.meta.crm_mods,
      crm_offer_response: this.$route.meta.crm_offer,
      filteredSteps: [],
      processes: {},
      predictions: null,
      applied_education_level: null,
      applied_training: null,
      application_data: null,
      application_form_status: null,
      formData: {
        segmentation_name: null,
        training_id: null,
        process: null,
        dropout_steps: null,
        conclusion_steps: null,
        education_level: null,
        modality: null,
        offers: null,
      },
      loading_status: 0,
      val_loading_status: 0,
      train_title: "",
      selected_training: null,
      validation_object: null,
      version_form_status: null,
      scope: {},
      switch_: { status: false, label: "Desativado" },
      selected_version: [],
      selected_versions_json: {},
      tab: null,
      show: false,
      application_model_show_details: false,
      showCards: {},
      showProcesses: {},
      warning_status: null,
      buttonClicked: false,
      requiredRule: [(v) => v !== null && v !== undefined && v !== ""],
      Rules: [
        (value) => {
          if (value?.length > 0 && value != "") return true;
          else {
            return "Por favor, preencha o campo acima";
          }
        },
      ],
    };
  },
  name: "ConfigurationView",
  components: {
    BackButton,
    WarningPopup,
    BarChart,
    LoadingStatus,
  },
  methods: {
    toggleSelectAll() {
      if (this.isSelectAll) {
        this.formData.offers = [];
      } else {
        this.formData.offers = this.crm_offer_response
          .map((item) => item.id)
          .slice();
      }
    },
    toggleSelectAllEducation() {
      if (this.isSelectAlleducation) {
        this.formData.education_level = [];
      } else {
        this.formData.education_level = this.crm_edulevel_response
          .map((item) => item.id)
          .slice();
      }
    },
    toggleSelectAllModality() {
      if (this.isSelectAllmodality) {
        this.formData.modality = [];
      } else {
        this.formData.modality = this.crm_modality_response
          .map((item) => item.id)
          .slice();
      }
    },
    ChangeSwitchStatusWarning(text, value) {
      this.switch_.label = text;
      this.switch_.status = value;
    },
    async toggleSwitchStatus() {
      if (this.switch_.status) {
        this.switch_.status = false;
        this.switch_.label = "Desativado";
        await Post(this.switch_.status, "switchconfigstatus");
      } else {
        this.switch_.status = true;
        this.switch_.label = "Ativado";
        await Post(this.switch_.status, "switchconfigstatus");
      }
    },
    resetFormData() {
      this.formData = {
        segmentation_name: null,
        training_id: null,
        process: null,
        dropout_steps: null,
        conclusion_steps: null,
        education_level: null,
        modality: null,
        offers: null,
      };
    },
    changeStyle() {
      let progressDIV = document.getElementById("progress-div");

      if (progressDIV) {
        if (this.formData.training_id != null) {
          progressDIV.style.visibility = "visible";
        }
      }
    },
    getSelectedTrainName(trainid) {
      if (trainid) {
        const result = this.versions_post_response.find(
          (train) => train.train_id == trainid
        ).train_prediction;
        return this.formatNumber(result);
      }
    },
    FilterSteps() {
      let selectedProcesses = this.formData.process;

      if (selectedProcesses && selectedProcesses.length > 0) {
        let filteredItems = [];

        selectedProcesses.forEach((processId) => {
          let process = this.crm_process_response.find(
            (item) => item.id === processId
          );
          this.processes[processId] = [];
          if (process) {
            filteredItems.push({
              id: `p-${process.id}`,
              processo_id: processId,
              name: process.nome,
              itemProps: { disabled: true, class: "process" },
            });
            process.etapas.forEach((etapa) => {
              this.processes[processId].push(etapa.id);
              filteredItems.push({
                id: etapa.id,
                name: etapa.titulo,
                processo_id: process.id,
                itemProps: { disabled: false },
              });
            });
          }
        });

        this.filteredSteps = filteredItems;
      }
    },
    formatDate,
    async RefreshHandler() {
      this.versions_post_response = await Get("listalltrainings");
    },
    WarninigHandler(value) {
      this.warning_status = value;
    },
    WarningStatusHandler(value) {
      this.resetFormData();
      this.warning_status = value;
    },
    ChangeValue(value) {
      this.buttonClicked = value;
      this.warning_status = false;
    },
    async PopulateGraph() {
      this.predictions = await Get("getupdatedregisters");
    },
    async renderScreen() {
      this.application_data = await Get("getconfigs");
      if (this.application_data.education_level) {
        this.applied_training = this.versions_post_response.find(
          (train) => train.train_id == this.application_data.trained_model_id
        );

        this.applied_education_level = this.crm_edulevel_response
          .filter((edu) =>
            JSON.parse(
              this.application_data.education_level.replace(/'/g, '"')
            ).includes(edu.id)
          )
          .map((edu) => edu.titulo);

        this.scope.dropout_steps = JSON.parse(
          this.application_data.dropout_steps.replace(/'/g, '"')
        );
        this.scope.conclusion_steps = JSON.parse(
          this.application_data.conclusion_steps.replace(/'/g, '"')
        );

        this.scope.processes = JSON.parse(
          this.application_data.processes.replace(/'/g, '"')
        );
        if (this.application_data.status == 1) {
          this.buttonClicked = true;
          this.switch_.status = true;
          this.switch_.label = "Ativado";
        } else {
          this.buttonClicked = true;
          this.switch_.status = false;
          this.switch_.label = "Desativado";
        }
      }
    },
    async submitForm() {
      await PostWithFile("saveconfigs", null, null, null, this.formData);
      this.renderScreen();
      this.ChangeValue(true);
    },
    ToogleCard(index) {
      this.showCards = { ...this.showCards, [index]: !this.showCards[index] };
    },
    ToogleProcess(index) {
      this.showProcesses = {
        ...this.showProcesses,
        [index]: !this.showProcesses[index],
      };
    },
    SaveSelectedVersion(item, value, index) {
      if (!this.selected_versions_json) {
        this.selected_versions_json = {};
      }

      this.selected_versions_json["Nome"] = this.train_title;

      if (item && item.versions) {
        const selectedItem = item.versions.find(
          (version) => version.version === value
        );

        const dataTypeObject = {
          data_type_name: item.data_type,
          data_type_id: index,
          selected_option_title: selectedItem ? selectedItem.title : "",
          selected_option_id: value,
        };

        if (!this.selected_versions_json.data_types) {
          this.selected_versions_json.data_types = [];
        }
        const existingIndex = this.selected_versions_json.data_types.findIndex(
          (obj) => obj.data_type_id === item.data_type_id
        );

        if (existingIndex !== -1) {
          this.selected_versions_json.data_types.splice(
            existingIndex,
            1,
            dataTypeObject
          );
        } else {
          this.selected_versions_json.data_types.push(dataTypeObject);
        }
      } else {
        console.error("item or item.versions is undefined or null.");
      }
    },

    async TrainModel() {
      this.train_title = "";
      this.selected_version = [];

      this.loading_status = 1;
      const post_result = await PostWithFile(
        "saveversions",
        null,
        null,
        this.selected_versions_json
      );
      if (post_result) {
        this.loading_status = 0;
      }
    },
    async selectedTrainingHandler() {
      this.val_loading_status = 1;
      this.validation_object = await Get(
        "listvalidationitems/" + this.selected_training
      );

      this.val_loading_status = 0;
    },
    formatNumber(value) {
      if (typeof value === "number") {
        return value.toLocaleString("pt-br", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
      }
      return value;
    },
  },
  watch: {
    "formData.training_id": "changeStyle",
    "formData.process": "FilterSteps",
  },
  async mounted() {
    formatDate();
    await this.renderScreen();
    this.PopulateGraph();
  },
  computed: {
    filteredTrainings() {
      return this.versions_post_response.filter(
        (train) => train.train_status === 3
      );
    },
    filteredDropoutSteps() {
      return this.filteredSteps.filter((step) => {
        if (step.id.indexOf("p-") >= 0) {
          return this.processes[step.processo_id].find(
            (elm) => !this.formData.conclusion_steps?.includes(elm)
          );
        }
        return !this.formData.conclusion_steps?.includes(step.id);
      });
    },
    filteredConclusionSteps() {
      return this.filteredSteps.filter((step) => {
        if (step.id.indexOf("p-") >= 0) {
          return this.processes[step.processo_id].find(
            (elm) => !this.formData.dropout_steps?.includes(elm)
          );
        }
        return !this.formData.dropout_steps?.includes(step.id);
      });
    },
    isSelectAll() {
      return (
        this.formData.offers?.length ===
        this.crm_offer_response.map((item) => item.id).length
      );
    },
    isSelectSomeOptions() {
      return this.formData.offers?.length > 0;
    },
    isSelectAlleducation() {
      return (
        this.formData.education_level?.length ===
        this.crm_edulevel_response.map((item) => item.id).length
      );
    },
    isSelectedSomeEducation() {
      return this.formData.education_level?.length > 0;
    },
    isSelectAllmodality() {
      return (
        this.formData.modality?.length ===
        this.crm_modality_response.map((item) => item.id).length
      );
    },
    isSelectedSomeModality() {
      return this.formData.modality?.length > 0;
    },
  },
};
</script>

<style>
.validation-table {
  margin-top: 10px;
  width: 75%;
  height: 500px;
}

.validation-table .v-table__wrapper {
  border: 1px solid #e0e0e0;
}

.validation-table tr {
  display: table-row !important;
}

.v-selection-control__input {
  color: #0da6a6;
}
.process {
  margin-left: -45px;
}

.train-wrapper {
  display: flex;
}

.first-part {
  margin-left: 15px;
  width: 45%;
}

.first-part-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 22px;
  margin-left: 16px;
  margin-right: 12px;
}

.first-part-header p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.hr {
  width: 1px;
  height: 20px;
  background-color: #d4d4d4 !important;
  margin-top: 0;
  margin-left: 55px;
}

.hr-gap {
  padding-right: 32px;
}

.hr-gap:last-of-type .hr {
  display: none;
}

.first-part {
  overflow-y: scroll;
  height: 77vh;
}

.show-content {
  margin-top: 23px;
  margin-bottom: 23px;
  width: 350px;
  margin-left: -53px;
}

.v-window__container {
  margin-top: 20px !important;
}

.train-title {
  margin-left: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #4d4d4d;
}

.train-date {
  margin-top: 4px;
  margin-left: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #808080;
}

.loading-train-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.training-chip {
  height: 21px;
  padding: 8px 14px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: #0da6a6;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.training-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.training-waiting-chip {
  height: 24px;
  padding: 8px 14px;
  border-radius: 16px;
  background-color: #a2a2a2;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  --fa-animation-duration: 2s;
  --fa-fade-opacity: 0.45;
}

.circle-score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #d4d4d4;
}

.circle-score p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.data-version-text {
  margin-top: -10px;
  margin-left: 12px;
  width: 500px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
}

.second-part-wrapper {
  width: 53%;
}

.second-part {
  width: 102%;
  overflow-y: scroll;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-part-first-text {
  margin-top: 24px;
  width: 80%;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 23.13px;
  letter-spacing: 0.025em;
  text-align: left;
}

.train-title-input {
  margin-top: 24px;
  width: 80%;
  max-height: 56px;
}

.second-part-cards {
  margin-bottom: 16px;
  width: 80%;
}

.second-part-data-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.second-part-data-text p {
  margin-top: 12px;
  margin-left: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #1a1a1a;
}

.second-part-data-text .v-sheet {
  margin-top: 10px;
  margin-right: 10px;
}

.second-part-select {
  margin-top: 35px;
  margin-left: 16px;
  width: 90%;
  padding-bottom: 85px;
}

.button-area {
  box-shadow: 0 -5px 5px rgba(202, 201, 201, 0.5);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: 12px;
}

.training-loading-container {
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.training-loading-image {
  position: relative;
  width: 61.73px;
  height: 61.73px;
}

.training-loading-text {
  margin-top: 20px;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18.09px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #0da6a6;
}

.validation-container {
  margin-top: 32px;
  margin-left: 32px;
}

.validation-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 23.13px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #4d4d4d;
}

.validation-header-link a {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.09px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #0da6a6;
}

.validation-select {
  margin-top: 32px;
  width: 512px;
}

.results-visualization {
  display: flex;
  flex-direction: row;
}

.score-progress-bar {
  margin-left: 100px;
  margin-top: 120px;
}

.score-visualization {
  display: flex;
  flex-direction: column;
}

.score-number {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: center;
  color: #263238;
}

.score-text {
  text-transform: uppercase;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #263238;
}

.three-part-main-text {
  margin-left: 32px;
  margin-top: 12px;
  margin-bottom: 30px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
}

.identification-group {
  padding: 12px;
}

.v-select .v-field .v-field__input > input {
  background-color: transparent;
}

.expand-button {
  margin-top: -85px;
}

.model-group {
  padding: 20px;
  width: 600px;
}

.capitation-process {
  padding: 20px;
}

.model-group .model-content {
  display: inline-flex;
  width: 552px;
  gap: 24px;
}

.label-name {
  margin-bottom: 16px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #4d4d4d;
}

.exceptional-steps {
  padding: 20px;
}

.target-audience {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.expectional-steps-content {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.add-seg-btn {
  display: none;
  margin-bottom: 20px;
  margin-left: 32px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #000000de;
  text-transform: none;
}

.change-application-config-btn {
  margin-bottom: 20px;
  margin-left: 32px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #000000de;
  text-transform: none;
}

.three-part-header {
  width: calc(100vw - 78px);
  display: flex;
  justify-content: space-between;
}

.aplication-view {
  margin-top: -12px;
  margin-left: 37px;
  display: flex;
}

.small-label-size {
  margin-left: 18px;
  margin-bottom: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d;
}

.horizontal-container {
  display: flex;
  margin-left: 20px;
}

.custom-horizontal-bar {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  margin-right: 10px;
}

.container-bar {
  width: 95%;
  margin-left: 20px;
  display: flex;
  gap: 4px;
}

.bar-content {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
}

#pointer {
  width: 100%;
  height: 25px;
  position: relative;
  background: #d4d4d4;
  margin-right: -10px;
}

#pointer-green {
  width: 100%;
  height: 25px;
  position: relative;
  background: #9eebc1;
  margin-right: -10px;
}

#pointer-red {
  width: 100%;
  height: 25px;
  position: relative;
  background: #fcbab5;
  margin-right: -10px;
}

#pointer:after,
#pointer-green:after,
#pointer-red:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 12px solid transparent;
  border-bottom: 13px solid transparent;
}

#pointer:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #d4d4d4;
  border-top: 12px solid transparent;
  border-bottom: 13px solid transparent;
  z-index: 1;
}

#pointer-red:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #fcbab5;
  border-top: 12px solid transparent;
  border-bottom: 13px solid transparent;
  z-index: 1;
}

#pointer-green:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #9eebc1;
  border-top: 12px solid transparent;
  border-bottom: 13px solid transparent;
  z-index: 1;
}

.bar-content:first-child #pointer,
.bar-content:first-child #pointer-green,
.bar-content:first-child #pointer-red {
  border-radius: 5px 0 0 5px;
}

.bar-content:first-child #pointer:after,
.bar-content:first-child #pointer-green:after,
.bar-content:first-child #pointer-red:after {
  border-left: none;
}

.bar-content:last-child #pointer:before,
.bar-content:last-child #pointer-green:before,
.bar-content:last-child #pointer-red:before {
  border-left: none;
}

.bar-content:last-child #pointer,
.bar-content:last-child #pointer-green,
.bar-content:last-child #pointer-red {
  width: 100%;
  border-radius: 0 5px 5px 0;
}

.stage-details {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  margin-left: 24px;
}

.dropout-details {
  border: 1px solid #f7685b;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  color: #f7685b;
  border-radius: 20px;
}

.success-details {
  border: 1px solid #23bf6a;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  color: #23bf6a;
  border-radius: 20px;
}

.floating-bar {
  position: fixed;
  height: 63px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
}

.btns-field {
  display: flex;
  gap: 10px;
  margin-right: 33px;
}

.updated-number {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  margin-right: 28px;
  color: #0da6a6;
}

.barchart-wrapper {
  width: 90%;
  margin-top: 50px;
  margin-left: 26px;
}

.barchart-wrapper canvas {
  height: 100% !important;
}

.chart-content-wrapper {
  margin-left: 21px;
  margin-right: 21px;
}

.chart-content-wrapper:last-of-type .content-sep {
  display: none;
}

.content-sep {
  height: 1px;
  background-color: #d4d4d4;
}

.chart-content-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #808080;
}

.no-results-visualization {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: -30px;
}

.no-results-visualization h2 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  color: #808080;
  margin-bottom: 20px;
}

.no-results-visualization p {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #a2a2a2;
}

.segmentation-field {
  width: 480px;
}

.segmentation-field .v-field__input {
  background-color: #f5f4f4;
}

::-webkit-scrollbar {
  height: 8px;
}

@media (max-width: 1023px) {
  .score-progress-bar {
    margin-top: 30px;
    margin-bottom: 60px;
    margin-left: 10px;
  }

  .results-visualization {
    display: flex;
    flex-direction: column-reverse;
  }

  .validation-table {
    width: 90%;
    margin-bottom: 20px;
  }

  .first-part {
    min-width: 50%;
  }
  .second-part-wrapper {
    min-width: 49.5%;
  }
  .application-form-container {
    width: 90%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .validation-table {
    width: 95%;
    margin-bottom: 20px;
  }

  .first-part {
    min-width: 50%;
  }

  .second-part-wrapper {
    min-width: 49%;
  }

  .right-side {
    margin-left: 8px;
    max-width: 45%;
  }

  .results-visualization {
    display: flex;
    flex-direction: column-reverse;
  }

  .score-progress-bar {
    margin-top: 30px;
    margin-bottom: 60px;
    margin-left: 10px;
  }

  .barchart-wrapper {
    margin-left: 13px;
  }

  .application-form-container {
    width: 85%;
  }
}

@media (min-width: 1280px) {
  .right-side {
    margin-left: 6px;
    min-width: 47%;
  }

  .validation-table {
    width: 95%;
    margin-bottom: 20px;
  }

  .score-progress-bar {
    margin-top: 30px;
    margin-bottom: 60px;
    margin-left: 10px;
  }

  .results-visualization {
    display: flex;
    flex-direction: column-reverse;
  }
  .application-form-container {
    width: 70%;
  }
}

@media (min-width: 1366px) {
  .right-side {
    margin-left: 8px;
    min-width: 47%;
  }
  .application-form-container {
    width: 65%;
  }

  .results-visualization {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 1400px) {
  .right-side {
    margin-left: 8px;
    max-width: 30%;
  }

  .barchart-wrapper {
    margin-left: 13px;
    width: 93%;
  }

  .application-form-container {
    width: 60%;
  }
}

@media (min-width: 1440px) {
  .right-side {
    margin-left: 10px;
    max-width: 30%;
  }
  .barchart-wrapper {
    margin-left: 13px;
    width: 93%;
  }
  .application-form-container {
    width: 60%;
  }
}

@media (min-width: 1600px) {
  .validation-table {
    width: 75%;
    margin-bottom: 20px;
  }
  .right-side {
    margin-left: 15px;
    max-width: 40%;
  }

  .score-progress-bar {
    margin-top: 110px;
    margin-bottom: 60px;
    margin-left: 50px;
  }

  .results-visualization {
    display: flex;
    flex-direction: row;
  }

  .barchart-wrapper {
    margin-left: 13px;
    width: 95%;
  }
  .application-form-container {
    width: 55%;
  }
}

@media (min-width: 1920px) {
  .validation-table {
    width: 80%;
    margin-bottom: 10px;
  }
  .right-side {
    margin-left: 25px;
    min-width: 48%;
  }
  .barchart-wrapper {
    width: 95%;
  }

  .score-progress-bar {
    margin-top: 110px;
    margin-bottom: 60px;
  }
  .application-form-container {
    width: 45%;
  }
}

@media (min-width: 2000px) {
  .validation-table {
    width: 80%;
    margin-bottom: 10px;
  }
  .right-side {
    margin-left: 30px;
    min-width: 48%;
  }

  .score-progress-bar {
    margin-top: 110px;
    margin-bottom: 60px;
    margin-left: 85px;
  }

  .barchart-wrapper {
    width: 98%;
  }
  .application-form-container {
    width: 27%;
  }

  .all_selection_span {
    font-size: 20px;
  }
}
</style>
