import { Get } from "@/http/http.js";

const ConfigurationResolver = async (to, from, next) => {
  let [av, trainings, crmprocs, crmedulevel, crmmods, crmoffer] =
    await Promise.all([
      Get("availableversions"),
      Get("listalltrainings"),
      Get("listprocess"),
      Get("listeducationlevel"),
      Get("listmodality"),
      Get("listoffer"),
    ]);

  to.meta["available_versions"] = av;
  to.meta["all_trainings"] = trainings;
  to.meta["crm_process"] = crmprocs;
  to.meta["crm_edulevel"] = crmedulevel;
  to.meta["crm_mods"] = crmmods;
  to.meta["crm_offer"] = crmoffer;
  next();
};
export default ConfigurationResolver;
