<template>
  <div style="margin-top: 17px"></div>
  <MainCard
    title="Dados"
    subtitle="Reservatório digital onde dados de diferentes fontes são coletados, armazenados e processados."
    sb="Saiba mais"
    link="https://crmrubeus.zendesk.com/hc/pt-br/articles/32260485588749-Acessando-as-configurações-de-fontes-de-dados-no-AI-Studio"
    :beta="true"
  >
    <StandardCard
      :items="CardData"
      @action="ButtonAction($event)"
    ></StandardCard>
  </MainCard>
</template>

<script>
import MainCard from "@/components/MainCard.vue";
import StandardCard from "@/components/StandardCard.vue";

export default {
  name: "DataView",
  data() {
    return {
      CardData: this.$route.meta.data_names,
    };
  },
  components: {
    MainCard,
    StandardCard,
  },
  methods: {
    ButtonAction(data) {
      this.$router.push("/definitions/" + data.id);
    },
  },
};
</script>
