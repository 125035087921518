import { createRouter, createWebHistory } from "vue-router";
import ConfigurationView from "../views/ConfigurationView.vue";
import DataView from "../views/DataView.vue";
import MainView from "../views/MainView.vue";
import ModelView from "../views/ModelView.vue";
import DataDefinitions from "@/views/DataDefinitions.vue";
import AuthResolver from "../resolver/AuthResolver";
import DataResolver from "../resolver/DataResolver";
import DataTypesResolver from "../resolver/DataTypesResolver";
import Page403View from "@/views/Page403View.vue";
import Page404View from "@/views/Page404View.vue";
import ConfigurationResolver from "@/resolver/ConfigurationResolver";
import ModelsResolver from "@/resolver/ModelsResolver";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "" },
    component: MainView,
    children: [
      {
        path: "/",
        name: "data",
        component: DataView,
        meta: { title: "Dados" },
        beforeEnter: DataResolver,
      },
      {
        path: "model",
        name: "model",
        meta: { title: "Modelos" },
        component: ModelView,
        beforeEnter: ModelsResolver,
      },
      {
        path: "/definitions/:id",
        name: "datadefinitions",
        meta: { title: "Definições" },
        component: DataDefinitions,
        beforeEnter: DataTypesResolver,
      },
      {
        path: "configuration",
        name: "configuration",
        meta: { title: "Configuração" },
        component: ConfigurationView,
        beforeEnter: ConfigurationResolver,
      },
      {
        path: "/assistentes",
        name: "assistentes",
        meta: { title: "Assistentes" },
        component: DataView,
        beforeEnter(to, from, next) {
          const wasRedirectedFromExternal = localStorage.getItem(
            "wasRedirectedFromExternal"
          );

          const token = localStorage.getItem("assistentesToken");

          if (wasRedirectedFromExternal) {
            localStorage.removeItem("wasRedirectedFromExternal");
            next("/assistentes");
          } else {
            localStorage.setItem("redirectedFromAssistentes", true);
            if (token) {
              const redirectUrl = `https://api.aistudio.apprbs.com.br/auth/${token}`;
              location.href = redirectUrl;
            } else {
              console.error("Token não encontrado");
              next(false);
            }
          }
        },
      },
    ],
  },
  {
    path: "/auth/:jwt",
    name: "auth",
    meta: { title: "Permanência" },
    component: Page403View,
    beforeEnter: AuthResolver,
  },
  {
    path: "/403",
    name: "403",
    meta: { title: "403" },
    component: Page403View,
  },
  {
    path: "/:catchAll(.*)",
    meta: { title: "404" },
    component: Page404View,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("key");
  const wasRedirectedFromAssistentes = localStorage.getItem(
    "redirectedFromAssistentes"
  );

  document.title = to.meta.title || "FallBack";

  if (to.name != "403" && to.name != "auth" && !isAuthenticated) {
    next({ name: "403" });
  } else if (wasRedirectedFromAssistentes) {
    localStorage.removeItem("redirectedFromAssistentes");
    next();
  } else next();
});

export default router;
