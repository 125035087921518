<template>
  <div class="loading-wrapper">
    <div class="loading-container">
      <img
        class="loading-image"
        src="https://arquivos.apprbs.com.br/Rubeus/rbGif.gif"
      />
      <p class="loading-text">
        Aguarde, este processo pode demorar <br />
        alguns segundos...
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingStatus",
};
</script>

<style>
.loading-wrapper {
  display: flex;
  justify-content: center;
  margin-left: -70px;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: #ffffff80;
  backdrop-filter: blur(3.5px);
  z-index: 1002;
}

.loading-container {
  margin-top: -225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-image {
  position: relative;
  width: 61.73px;
  height: 61.73px;
  z-index: 1003;
}

.loading-text {
  margin-top: 20px;
  font-family: Roboto !important;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18.09px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #0da6a6;
}
</style>
