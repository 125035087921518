<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="updateDialog"
    :persistent="false"
    max-width="700"
    max-height="440"
  >
    <v-card>
      <v-card-title
        style="
          margin-top: 15px;
          margin-left: 10px;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          color: #1a1a1a;
        "
        class="headline"
        >Erros na base de dados</v-card-title
      >
      <v-card-text
        v-if="errors && errors.length > 0"
        style="
          margin-top: 5px;
          margin-left: 5px;
          font-family: Roboto;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.025em;
        "
        >Encontramos alguns problemas na versão
        <strong>{{ this.errors[0].version_name }}</strong> da sua base de
        dados.</v-card-text
      >
      <header
        style="
          border-bottom: 1px solid #cccccc;
          line-height: 42px;
          margin-left: 30px;
          margin-right: 20px;
        "
        class="d-flex flex-row justify-start"
      >
        <p
          style="
            margin-left: 12px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
          "
        >
          Erro
        </p>
        <p
          style="
            margin-left: 190px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
          "
        >
          Coluna
        </p>
      </header>
      <div
        style="width: 651px; height: 214px; margin-left: 30px; overflow-y: auto"
      >
        <div
          v-for="error in errors"
          :key="error"
          class="d-flex flex-row justify-start"
          style="line-height: 45px; border-bottom: 1px solid #cccccc"
        >
          <p
            style="
              width: 175px;
              margin-left: 12px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              color: #808080;
              line-height: 20px;
            "
          >
            {{ error.error }}
          </p>
          <p
            style="
              margin-left: 41px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              color: #808080;
            "
          >
            {{ error.field }}
          </p>
        </div>
      </div>

      <v-card-actions class="errors-actions">
        <v-spacer></v-spacer>
        <v-btn
          style="
            text-transform: none;
            padding: 9px;
            opacity: 0px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
          "
          @click="updateDialog(false)"
          >Fechar</v-btn
        >
        <v-btn
          style="
            color: white;
            background-color: #0da6a6;
            text-transform: none;
            padding: 9px 9px;
            border-radius: 4px;
            opacity: 0px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
          "
          @click="openDialog(false)"
          >Importar nova versão</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorsPoppup",
  props: {
    dialog: {
      type: Boolean,
      required: false,
    },
    errors: Array,
  },
  methods: {
    updateDialog(value) {
      this.$emit("update:dialog", value);
    },
    openDialog(value) {
      this.updateDialog(value);
      this.$emit("open-dialog", true);
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #d4d4d4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #616161;
}

.errors-actions {
  margin-top: 32px;
  margin-right: 12px;
  margin-bottom: 16px;
  gap: 10px;
}
</style>
