import { APIAuth } from "@/http/http.js";

const AuthResolver = async (to, from, next) => {
  let at = await APIAuth("auth", to.params.jwt);
  if (at) {
    localStorage.setItem("key", true);
    next({
      path: "/",
      replace: true,
    });
  } else {
    localStorage.clear("key");
    next({ path: "/403", replace: true });
  }
};

export default AuthResolver;
