<template>
  <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script>
import { formatDate } from "@/utils/dataformatter";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    values: Array,
  },
  methods: {
    formatDate,
  },
  data() {
    return {
      chartData: {
        labels: formatDate(
          this.values.map((item) => item.updated_at),
          0
        ),
        datasets: [
          {
            label: false,
            data: this.values.map((item) => item.qtd_registers),
            backgroundColor: "#004747",
            barPercentage: 1,
            borderRadius: 5,
          },
        ],
      },
      chartOptions: {
        plugins: {
          legend: false,
          chartAreaBorder: {
            borderColor: "red",
          },
        },
        scales: {
          x: {
            ticks: { display: false },
            grid: { display: false },
          },
          y: {
            ticks: { display: false },
            grid: { display: false, lineWidth: "0px" },
          },
        },
        responsive: true,
      },
    };
  },
};
</script>
