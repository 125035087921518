<template>
  <v-col
    class="ml-0 v-100 h-100"
    style="
      background-color: #fff;
      border-radius: 5px 0px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
    "
  >
    <v-row
      v-if="(title != none) | (subtitle != none)"
      class="d-flex flex-column text-left"
      style="margin: 0"
    >
      <div
        v-if="title != none"
        class="d-flex justify-space-between pl-8 pt-5 pb-0 text-h5"
        style="
          color: #1a1a1a;
          font-family: Poppins;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        "
      >
        <div style="display: flex; align-items: center; gap: 20px" class="dv">
          {{ title }}
          <v-chip
            v-if="beta"
            class="beta-chip"
            variant="tonal"
            border="0"
            color="#7dcfcf"
            rounded="lg"
            text="BETA"
          ></v-chip>
        </div>

        <div v-if="btnactivated == 1">
          <v-text
            v-if="last_update != none"
            style="
              color: #4d4d4d;
              font-family: Roboto;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 154.2%;
              letter-spacing: 0.375px;
              padding-right: 32px;
            "
          >
            Última atualização: {{ formatDate(last_update) }}</v-text
          >
          <v-dialog
            ref="importdialog"
            height="700"
            width="840"
            v-model="dialogVisible"
            transition="dialog-top-transition"
          >
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn
                @click="ResetCard"
                v-bind="activatorProps"
                prepend-icon="mdi-upload"
                text="Importar Dados"
                variant="flat"
                style="
                  margin-right: 32px;
                  display: inline-flex;
                  padding: 9px;
                  align-items: center;
                  color: white;
                  border-radius: 4px;
                  background: #0da6a6;
                  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25),
                    0px 1px 2px 0px rgba(0, 0, 0, 0.12),
                    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 17.807px;
                  text-transform: none;
                "
              ></v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card title="Importar Dados">
                <div
                  class="d-flex flex-row"
                  style="margin-left: 31px; margin-bottom: 11px; gap: 69px"
                >
                  <div>
                    <v-card-text
                      style="
                        color: #1a1a1a;
                        font-family: Roboto;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 154.2%;
                        letter-spacing: 0.375px;
                        margin-left: -18px;
                      "
                    >
                      Adicione um arquivo .CSV para a importação dos dados.
                    </v-card-text>

                    <ul style="margin-left: 15px; margin-bottom: 24px">
                      <li
                        style="
                          color: #808080;
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 0;
                        "
                      >
                        Para te ajudar, temos um
                        <a
                          style="color: #0da6a6"
                          :href="'/api/returncsv/' + this.$route.params.id"
                          >modelo disponível</a
                        >
                      </li>
                    </ul>
                  </div>

                  <div class="d-flex flex-column">
                    <v-form ref="form">
                      <v-text-field
                        @change="ValidateForm"
                        :rules="Rules"
                        class="version-input"
                        label="Título da versão*"
                        maxlength="34"
                        style="width: 300px; height: 85px"
                        v-model="version_name"
                      ></v-text-field>

                      <p class="hint-count" v-if="version_name == null">
                        0 / 34
                      </p>
                      <p class="hint-count" v-if="version_name != null">
                        {{ version_name.length }} / 34
                      </p>
                    </v-form>
                  </div>
                  <v-btn
                    variant="plain"
                    class="close-button-top"
                    @click="isActive.value = false"
                    :onclick="ResetCard"
                    :min-width="15"
                  >
                    X
                  </v-btn>
                </div>

                <DataUploader
                  :dheight="carduploadheight"
                  @SetFile="SetFile($event)"
                  :validation_return="validation_return"
                  @update-validation_return_success="
                    handleUpdateValidationReturn
                  "
                  @ChangeCardHeight="handleCardheight"
                ></DataUploader>

                <v-card-actions class="main-dialog-actions">
                  <v-spacer></v-spacer>

                  <v-btn
                    style="
                      color: rgba(0, 0, 0, 0.87);
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 17.807px;
                      text-transform: none;
                      border-radius: 4px;
                    "
                    text="Cancelar"
                    @click="isActive.value = false"
                    :onclick="ResetCard"
                  ></v-btn>
                  <v-btn
                    :disabled="Isformvalid"
                    @click="
                      UploadFile();
                      dialogVisible = false;
                    "
                    style="
                      color: white;
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 17.807px;
                      text-transform: none;
                      border-radius: 4px;
                      background: #0da6a6;
                    "
                    text="Importar"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>

      <div
        v-if="subtitle != none"
        class="pl-8 pt-4 pb-8 text-body-1"
        style="color: gray; font-style: italic"
      >
        {{ subtitle }}
        <a
          :href="link"
          style="text-decoration: underline; color: #0da6a6; font-style: normal"
          >{{ sb }}</a
        >
      </div>
    </v-row>

    <div class="d-flex flex-wrap">
      <slot></slot>
    </div>
  </v-col>
</template>

<script>
import { PostWithFile, ValidateFile } from "@/http/http.js";
import DataUploader from "./DataUploader.vue";
import { formatDate } from "@/utils/dataformatter";
export default {
  data() {
    return {
      controller: null,
      selected_fields_status: null,
      carduploadheight: null,
      from_to_columns: null,
      missing: null,
      unknown: null,
      path: null,
      file: null,
      validation_return: null,
      version_name: null,
      Isformvalid: true,
      dialogVisible: null,
      Rules: [
        (value) => {
          if (value?.length > 0) return true;
          return "Campo Obrigatório";
        },
      ],
    };
  },
  name: "CardView",
  components: {
    DataUploader,
  },
  props: {
    open_from_errors_pop_up: Boolean,
    title: String,
    subtitle: String,
    sb: String,
    link: {
      type: String,
      default: "",
    },
    last_update: String,
    btnactivated: Number,
    beta: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    open_from_errors_pop_up: function () {
      this.dialogVisible = this.open_from_errors_pop_up;
    },
  },
  methods: {
    async SetFile(data) {
      if (this.controller) {
        this.controller.abort();
      }

      this.controller = new AbortController();
      this.validation_return = null;
      this.file = data;
      this.path = "checkfile/" + this.$route.params.id;
      this.validation_return = await ValidateFile(
        this.path,
        this.file,
        this.controller
      );
      this.ValidateForm();

      if (
        this.validation_return.error ==
          "Colunas obrigatórias não encontradas" &&
        this.file != null
      ) {
        this.carduploadheight = 750;
      }

      if (!this.validation_return.success) {
        this.file = null;
      }

      this.missing = this.validation_return.missing;
      this.unknown = this.validation_return.unknown;
    },
    ValidateForm() {
      if (this.selected_fields_status) {
        this.Isformvalid = !(
          this.$refs.form?.isValid &&
          this.selected_fields_status &&
          !this.file
        );
      } else {
        this.Isformvalid = !(
          this.$refs.form?.isValid &&
          this.validation_return?.success &&
          this.file
        );
      }
    },
    ResetCard() {
      if (this.controller) {
        this.controller.abort();
      }
      this.carduploadheight = 352;
      this.file = null;
      this.selected_fields_status = null;
      this.Isformvalid = true;
    },
    UploadFile() {
      this.ValidateForm();

      const data = [
        {
          name: "version_name",
          value: this.version_name,
        },
        {
          name: "data_type_id",
          value: this.$route.params.id,
        },
      ];

      PostWithFile("writefile", data, this.from_to_columns);
      this.dialogVisible = false;
    },
    formatDate,
    handleUpdateValidationReturn(value, from_to_columns) {
      if (
        this.validation_return.error == "Colunas obrigatórias não encontradas"
      ) {
        this.from_to_columns = from_to_columns;
      }
      this.selected_fields_status = value;

      this.ValidateForm();
    },
    handleCardheight(status, content, value) {
      this.carduploadheight = value;
      this.selected_fields_status = content;
      this.Isformvalid = status;
      if (this.controller) {
        this.controller.abort();
      }
    },
  },
  mounted() {
    formatDate();
  },
};
</script>

<style>
.main-dialog-actions {
  margin-right: 24px;
  gap: 16px;
  margin-bottom: 6px;
}

.v-field
  .v-field--active
  .v-field--center-affix
  .v-field--dirty
  .v-field--variant-filled
  .v-theme--light
  .v-locale--is-ltr {
  justify-content: flex-start;
}

.v-overlay__content .v-card-item {
  margin-top: 10px;
}

.version-input .v-messages {
  display: flex;
  justify-content: flex-end;
}

.v-overlay__content .v-card-item .v-card-title {
  margin-bottom: 20px;
  font-family: Poppins;
}

.hint-count {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.v-messages {
  margin-right: 160px;
}

.beta-chip {
  font-size: 12px !important;
}

.close-button-top {
  position: absolute;
  right: 32px;
  top: 26px;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
}
</style>
