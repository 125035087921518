<template>
  <div style="margin-top: 17px"></div>
  <MainCard
    :beta="true"
    title="Modelos"
    sb="Saiba Mais"
    link="https://crmrubeus.zendesk.com/hc/pt-br/articles/32261882979597-Modelos-de-Inteligência-Artificial-no-AI-Studio"
    subtitle="Modelos de IA são representações simplificadas do pensamento humano,  capacitando computadores a entender, aprender e agir inteligentemente  com base em dados e algoritmos."
  >
    <StandardCard
      :items="CardData"
      md="6"
      lg="5"
      xlg="5"
      actionActive="1"
      @action="ButtonAction($event)"
    ></StandardCard>
  </MainCard>
</template>

<script>
import MainCard from "@/components/MainCard.vue";
import StandardCard from "@/components/StandardCard.vue";

export default {
  name: "ConfigurationView",
  data() {
    return {
      CardData: this.$route.meta.models,
    };
  },
  components: {
    MainCard,
    StandardCard,
  },
  methods: {
    ButtonAction(data) {
      this.$router.push("/configuration", data);
    },
  },
};
</script>
