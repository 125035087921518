<template>
  <v-img cover height="100vh" src="../assets/403.svg"></v-img>
</template>

<script>
export default {
  name: "403Page",
  components: {},
};
</script>
