import { Get } from "@/http/http.js";

const DataTypesREsolver = async (to, from, next) => {
  const [data_types, data_versions, custom_fields] = await Promise.all([
    Get("listdatatypes/" + to.params.id),
    Get("listversions/" + to.params.id),
    Get("listcustomfields"),
  ]);
  to.meta["data_types"] = data_types;
  to.meta["data_versions"] = data_versions;
  to.meta["custom_fields"] = custom_fields;
  next();
};

export default DataTypesREsolver;
